@import "_bootstrap.scss";

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjTOQ_MqJVwkKsUn0wKzc2I.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBjUj_cnvWIuuBMVgbX098Mw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBiYE0-AqJ3nfInTTiDXDjU4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@media screen and (max-width: 1230px) {
  section#social-networks-bar {
    display: none !important;
  }
}

::selection {
  color: #fafafa;
  background: #da3b44;
}

$brand-primary: #428bca;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #ed7260;
$brand-danger: #d9534f;

$stars-yellow: #fde16d;

$facebook: #3b5998;
$twitter: #00aced;
$googlep: #dd4b39;
$youtube: #e02a26;
$pinterest: #cb2027;
$flickr: #ff0084;
$tumblr: #32506d; //yok
$vk: #45668e;
$vimeo: #aad450;
$foursquare: #00aeef;
$wordpress: #21759b;
$blogspot: #ff9334; //yok

$logo-green: #6c9d34;
$logo-red: #ed7260;
$logo-blue: #14adcf;
$logo-yellow: #e1c120;
$logo-orange: #e95c0b;

$pb-red: rgb(218, 59, 68);

@mixin opacity($level, $level_ms) {
  -moz-opacity: $level;
  -khtml-opacity: $level;
  opacity: $level;
  //-ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=$level_ms);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=$level_ms);
  filter: alpha(opacity=$level_ms);
}

$font-stack: 'Open Sans', Helvetica, Arial, sans-serif;

// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-thumb:vertical {
//   margin: 5px;
//   background-color: #666;
//   -webkit-border-radius: 5px;
// }

// ::-webkit-scrollbar-button:start:decrement,
// ::-webkit-scrollbar-button:end:increment {
//   height: 5px;
//   display: block;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   margin-top: -5px;
//   background-image: url('../img/bg.png');
//   background-repeat: repeat-x;
//   background-color: #d2eff5;
//   //position: relative;
// }

p {
  margin: 5px 0;
}

input.light {
  padding: 10px 5px;
}

.label {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.5em;
  white-space: inherit;
}

/* Vendor related styles */

.datepicker.dropdown-menu {
  width: 250px;
  height: 270px;

  button {
    outline: 0;
    border: 0;
  }

  tbody {
    //height: 180px;
    button {
      padding: 6px;
    }
  }

  &.datepicker-mode-1 tbody button, &.datepicker-mode-2 tbody button {
    height: 65px;
  }
}

.text-danger {
  color: $pb-red !important;
}

/* START PODARI-BUKET APPPLIED STYLES */

body {
  background-color: #f2f2f2;
  font-family: $font-stack;
  font-size: 13px;
}

select, input, textarea {
  //width: 100% !important;
  //margin: 0 10px 20px 10px !important;
}

form#saveDeliveryAddress {
  input, textarea {
    width: 100%;
  }
}

/* END PODARI-BUKET APPPLIED STYLES */

p {
  font-size: 13px;
}

a {
  &:hover {
    text-decoration: none
  }
}

.green {
  color: darken(#6c9d34, 5%);
}

.darkgrey {
  color: #AAA;
}

.red {
  color: lighten(#a94442, 15%);
}

.uppercase {
  text-transform: uppercase;
}

h1, h2, h3 {
  font-family: $font-stack;
  font-weight: 300;
  color: #444;

  small {

  }
}


h3 {
  color: #444444;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
  color: #444;
  font-weight: 600;

  .dotted-bottom {
    padding-bottom: 10px;
    border-bottom: 1px dotted #c0c0c0;
  }
}

.dotted-bottom {
  padding-bottom: 10px;
  border-bottom: 1px dotted #c0c0c0;
}

h4 {
  color: #444444;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

h4, h5, h6 {
  font-family: $font-stack;
}

h1#logo {
  margin: 0;
}

div.voucher-used {
  color: #999
}

a.voucher {
  color: #333;

  .disabled-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }

  &:hover {
    color: lighten(#333, 15%);

    div.voucher > h1 > small.green {
      color: lighten(#6c9d34, 5%);
    }
  }
}

div.voucher {
  h1 {
    margin-top: 10px;
  }
}

.btn-square {
  width: 100%;
  height: 100%;
  padding: 30px;
  margin-bottom: 20px;

  p.button-title {
    text-align: center
  }
}

span.capitalize {
  text-transform: uppercase;
}

div {
  &.page-container {
    //margin: 0;
    position: relative;
    //z-index: 1;
    min-height: 580px;
    padding-bottom: 10px;
  }


  &.right {
    text-align: right
  }
}

div.currencies {
  a.currency {
    float: left;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 1px solid #428bca;
    margin-top: 5px;
    margin-right: 3px;

    &:hover {
      text-decoration: none;
    }
  }

  a#current_currency {
    color: rgba(66, 139, 202, 0.5);
    border-color: rgba(66, 139, 202, 0.5);
  }
}

div.languages {
  a img {
    opacity: 0.8;
    filter: alpha(opacity=80);
  }

  a:hover img {
    opacity: 1.0;
    filter: alpha(opacity=100);
  }

  a#current_lang img {
    color: rgba(66, 139, 202, 0.5);
    border-color: rgba(66, 139, 202, 0.5);
  }
}


/* BOOTSTRAP NAV MULTILEVEL FIX */

.dropdown-submenu {
  position: relative;

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  &:hover {
    > .dropdown-menu {
      display: block;
    }

    > a:after {
      border-left-color: #fff;
    }
  }

  > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  &.pull-left {
    float: none;

    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
      -webkit-border-radius: 6px 0 6px 6px;
      -moz-border-radius: 6px 0 6px 6px;
      border-radius: 6px 0 6px 6px;
    }
  }
}

/* BUTTONS */

.product-buttons {
  padding: 6px, 20px;
}

.btn-product-view {
  float: right;
}

.btn-buy, .btn-expand {
  width: 100%;
  padding: 15px;
}

.btn.expand {
  width: 100%
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

/* START PODARI-BUKET APPPLIED STYLES */

/* HEADER TOP */
header.header-top {
  background-color: #3c3c3c;
  padding: 5px 0;

  div#user-actions {
    float: right;

    span {
      float: left;
      margin-right: 0;
      margin-left: 15px;
    }
  }

  span {
    margin-right: 15px;
  }

  a {
    color: #f7f7f7;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;

    &#current_currency, &#current_lang {
      color: #909090;
    }
  }
}

/* HEADER MAIN */
header.header-main {
  background-color: #fafafa;
  height: 70px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  z-index: 5;
  //margin-bottom: 5px;

  div#header-logo {
    display: table;
    height: 70px;
    margin-left: -5px;
    //margin-top: 3px;
    a {
      vertical-align: middle;
      display: table-cell;
    }
  }
}

/* END PODARI-BUKET APPPLIED STYLES */

.dntrade-header h1 {
  margin-top: 10px;
  font-size: 24px;
}

div#languages a:hover {
  text-decoration: none;
}

.important {
  color: $pb-red !important;
}

/* CART */
div#cart {
  margin: -5px 0;
  padding: 5px 0;

  &:hover > div#cart-wrap > section#cart-contents {
    display: block;
  }

  > section#cart-contents.empty {
    display: none;
  }
}

div#cart-wrap {
  position: relative;
  margin-top: 18px;
  //margin-bottom: 16px;
  list-style: none;
  background-clip: padding-box;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background: #f7f7f7;
  border: 1px solid #cecece;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  color: #555555;
  font-weight: 700;
  padding: 8px 12px;

  i.fa-shopping-cart {
    color: $pb-red;
    font-size: 1.5em !important;
    margin-right: 10px;
  }

  i.fa-chevron-down {
    float: right;
    margin-top: 8px;
    font-size: 10px;
  }

  &:hover {
    background-color: darken(#FFF, 5%);
  }

  span.do-not-show-on-empty-cart {
    display: none;
  }

  @media (max-width: 500px) {
    section#cart-contents {
      min-width: 300px !important;
    }
  }

  section#cart-contents {

    position: absolute;
    display: none;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;

    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);

    top: 43px;
    right: 0;
    background: #fcfcfc;
    width: auto;
    min-width: 350px;
    z-index: 5;

    div.products {
      padding: 10px 20px;

      ul#products {
        list-style-type: none;
        padding: 0;

        li.item {
          position: relative;

          div {
            padding: 5px !important;

            a, span {
              font-weight: 600;
              color: #444;
              margin-top: 15px;
              float: left;
              //line-height: 30px;

              i.fa-times {
                font-weight: 300;
                color: lighten(#444, 30%);
              }

              &:hover {
                > i.fa-times {
                  color: #444;
                }
              }
            }

            &.product-options {
              margin-top: -10px;

              small {
                font-weight: 400;
                font-size: 9px;
              }
            }
          }
        }

        img.cart-img {
          max-width: 100%;
        }
      }
    }

    div.cart-bottom {
      background: #f7f7f7;
      overflow: hidden;
      border-top: 1px solid #eaeaea;
      padding: 10px 20px;
      margin-top: 14px;

      border-radius: 0 0 2px 2px;
      -webkit-border-radius: 0 0 2px 2px;
      -moz-border-radius: 0 0 2px 2px;

      div.cart-bottom-row {
        overflow: hidden;
        border-bottom: 1px solid #eaeaea;
        padding: 10px 0;

        &:nth-child(1) {
          margin-top: -5px;
        }

        div {
          margin-left: -5px;

          span {
            font-weight: 600;
            color: #444;
          }
        }
      }

      div.cart-total {
        margin: 20px -15px;

        span.cart-total {
          font-size: 18px;
          color: #444;
          float: left;
          line-height: 30px;
          font-weight: 600;
        }

        a.exclusive {
          margin: 0;
        }
      }
    }
  }
}

div.cart-discount {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #EEE;
}

div#cart-notification {
  display: none;
  position: fixed;
  left: 35%;
  right: 35%;
  top: 0;
  width: 30%;
  padding: 10px;
  background-color: white;
  z-index: 1000;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.75);

  h4 {
    text-align: center;
  }
}

/* FRONT PAGE SLIDER */
//div#slider {
//  //margin-top: -10px;
//  height: 250px;
//  position: relative;
//
//  div.slider-caption {
//    position: absolute;
//    z-index: 3;
//    background-color: rgba(0,0,0,0.5);
//    top: 20px;
//    left: 30px;
//    right: 450px;
//    bottom: 40px;
//    padding: 15px;
//
//    h1 {
//      text-align: left !important;
//      color: white;
//    }
//
//    h2 {
//      color: #DDD;
//      text-align: right !important;
//    }
//
//    p {
//      text-align: left !important;
//      color: #CCC;
//    }
//
//    h1, h2 {
//      margin: 0px
//    }
//  }
//}

/* ORDER PAGE */
ul#order-cart-contents {
  padding: 0;

  li.order-cart-product {
    list-style-type: none;
    padding-bottom: 5px;
    margin-bottom: 15px;
    border-bottom: 1px dotted #EEE;
    position: relative;

    div.cart-item-control {
      position: absolute;
      bottom: 25%;
      right: 0px;
    }
  }
}

/* PODARI-BUKET */
img.product-img {
  max-width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  height: auto;
  margin: 1px 1px 3px 2px;

  -webkit-backface-visibility: hidden;

  border: 8px solid #ffffff;

  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.095), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);

}

///* /PODARI-BUKET */
//div#slider {
//  margin-top: -11px;
//  float: left
//}

//@-moz-document url-prefix() {
//  div#slider {
//    margin-top: -10px !important;
//  }
//}

hr.dotted {
  color: rgba(255, 255, 255, 0);
  border-top: 1px dotted #c0c0c0;
}

div.homepage-products {
  //clear: both;
  h4 {
    font-size: 18px;
    line-height: 24px;
    color: #444444;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #c0c0c0;
    font-weight: 600;
  }
}

/* FEATURED PRODUCTS */

ul.featured-products {
  padding-right: 0;

  li.featured-product {
    list-style-type: none;
    height: 350px;
    position: relative;
    padding-left: 0;
    text-align: center;

    a {
      color: #333;

      &:hover {
        text-decoration: none;

        > {
          p {
            color: #666;
          }
        }
      }
    }

    h4 {
      //color: darken($logo-green, 10%);
      font-family: $font-stack;
      font-size: 16px;
      margin-bottom: 0;
    }

    p {
      color: #999;
    }
  }
}

div.price-container {
  margin: 0;
  height: 20px;

  span.price {
    position: relative !important;
    margin: 0;
    color: #da3b44;
    font-weight: 700;
    font-size: 14px;
    cursor: default;
    text-align: center;
    display: inline-block;

    &.right-column {
      margin-left: 10px;
    }
  }

  span.old-price {
    display: inline-block;
    color: #444444;
    font-size: 12px;
    text-decoration: line-through;
    cursor: default;
    margin-left: 4px;
  }

  span.discount {
    position: absolute;
    top: 5px;
    right: 25px;
    color: #da3b44;
    vertical-align: top;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
  }

  span.discount-amount {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 225px;
    left: 25px;
    color: #da3b44;
    vertical-align: top;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px;
  }
}


/* PRODUCT PAGE */

#product-img {
  position: relative;

  img {
    max-width: 100%;
    cursor: pointer;

    &.product-thumbnail {
      float: left;
      opacity: 0.9;
      cursor: pointer;

      &:hover {
        opacity: 1.0
      }
    }
  }

  span.discount {
    position: absolute;
    top: 5px;
    right: 20px;
    color: #da3b44;
    vertical-align: top;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  span.discount-amount {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 320px;
    left: 20px;
    color: #da3b44;
    vertical-align: top;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px;
  }
}

#product-contents, .product-reviews, .content {
  h1 {
    color: #666666;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    display: block;
    clear: both;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: $font-stack;
  }

  h2 {
    color: #666;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    font-family: $font-stack;
  }

  h3 {
    small {
      font-size: 12px !important;
      font-family: $font-stack;
      font-weight: 400;
    }
  }

  span.price {
    padding: 0;
    font-weight: inherit;
    font-size: 28px;
    line-height: 32px;
    margin-right: 15px;
    color: #DA3B44;
    font-weight: 600;

    small.superscript {
      vertical-align: super;
      font-size: 12px;
    }
  }

  span.old-price {
    font-size: 14px;
    text-decoration: line-through;
    color: #909090;
  }

  p {
    color: #666;
    font-weight: 600;
  }

  em, strong {
    color: #666;
  }

  em.combinations-contents {
    display: none;
  }

  a {
    color: rgb(218, 59, 68);
  }
}

#product-accessories {
  h4 {
    margin-top: 0;
    font-family: $font-stack;
    color: #666;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
  }

  a {
    color: $pb-red;
    font-weight: 600;

    &:hover {
      color: darken($pb-red, 5%);
    }
  }

  p {
    color: #666;
  }

  ul#additions-right {
    padding: 0;

    li.addition-right {
      list-style-type: none;
      margin-right: 0;
      position: relative;
      margin-bottom: 10px;
      height: 180px;

      p {
        margin: 0;
      }

      &:hover {
        > span.price {
          background-color: darken(#6c9d34, 5%);
        }

        span.addition-name {
          background-color: #31b0d5;
        }
      }

      span.price {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      span.addition-name {
        position: absolute;
        bottom: 0px;
        left: 0px;
        //right: 0px;
        //background-color: #428bca;

        h5 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

div#gallery-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;

  span#gallery-close {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    font-size: 45px;
    color: #f2f2f2;
    cursor: pointer;

    &:hover {
      color: #fafafa;
    }
  }

  img {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: block;
  }
}

/* CATEGORY PAGE */

#category-description h3 {
  margin-top: 0;
  font-family: $font-stack;
}

li.additional-product {
  &:hover {
    > {
      p {
        color: #666 !important;
      }

      span.price {
        background-color: darken(#6c9d34, 5%) !important;
      }
    }
  }
}

ul#category-products, ul#additional-products, ul#featured-products {
  padding: 0;

  li.category-product, li.additional-product {
    list-style-type: none;
    height: 350px;
    margin-bottom: 0px;
    position: relative;
    text-align: center;

    a {
      color: #333;

      &:hover {
        text-decoration: none;

        > {
          p {
            color: #666;
          }
        }
      }

      div.pic-wrapper {
        position: relative;
        float: left;
      }
    }

    h4 {
      font-family: $font-stack;
      font-size: 21px;
      margin-bottom: 0;
      //color: darken($logo-green, 10%);
    }

    p {
      color: #999;

      &.description {
        text-align: left !important;
        color: #666;
        font-weight: 600;
        height: 40px !important;
        //margin-bottom: 50px;
      }
    }
  }
}

div#order-container ul#additional-products {
  li.additional-product {
    height: initial;
    margin-bottom: 20px !important;
  }
}

div#message-card {
  min-height: 200px;
}

.expand {
  width: 100% !important;
}

/* ORDER PAGE */
i#citySelectSpinner {
  display: none;
}

div#delivery-cities {
  position: relative;

  ul#delivery-cities {
    padding: 0;
    position: absolute;
    background-color: white;
    padding: 10px;
    width: 100%;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #c0c0c0;
    display: none;
    z-index: 100000;
    margin-top: -6px;

    li.city {
      position: relative;
      padding: 10px;
      list-style-type: none;
      width: 100%;

      &:hover {
        background-color: #EEE;
        cursor: pointer;
      }
    }

    li.city.no-delivery {
      color: #CCC;
      //cursor: not-allowed;
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
}

div.no-display {
  display: none;
}

div.panel-footer {
  overflow: hidden;
}

div.panel-heading {
  position: relative;
  cursor: pointer;

  &:hover {
    & > div.panel-controls {
      display: block;
    }
  }

  div.panel-controls {
    position: absolute;
    top: 15px;
    right: 15px;
    display: none;
  }
}

/* CMS PAGES */
div#cms-content {
  min-height: 600px;

  h1, h2, h3 {
    //font-family: 'yanonelight', $font-stack;
  }

  a {
    color: rgb(218, 59, 68) !important;
  }
}

div.rating-container {
  color: #AAA;
  position: relative;
  display: inline-block;
  overflow: hidden;
  //float: left;

  div.rating-overlay {
    color: #fde16d;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
  }
}

span.label-big {
  font-size: 120%
}

ul.order-reviews {
  padding: 0;

  li.review-panel {
    list-style-type: none;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
    color: #555;

    &:last-child {
      border-bottom: none;
    }
  }
}

div.reviewed-product {
  position: relative;

  span.product-name {
    position: absolute;
    bottom: 10%;
    left: 0;
  }
}

/* CUSTOMERS */
ul.my-acc-links-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 30px;
  margin-left: -15px;

  li {
    display: inline-block;
    position: relative;
    margin: 0 15px 30px 15px !important;

    a {
      display: table-cell;
      vertical-align: middle;
      background: #ffffff;

      border-top: 5px solid $pb-red;

      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;

      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.035), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.035), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.035), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.2), 0 2px 1.5px 0 rgba(0, 0, 0, 0.1);

      width: 250px;
      height: 150px;
      padding: 10px;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      color: #444444;

      &:hover {
        color: $pb-red;
        //text-decoration: underline;

        & > i {
          //font-size: 110%;
          color: darken($pb-red, 5%);
        }
      }

      i {
        color: #da3b44;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

ul#customer-orders, ul#customer-invoices {
  li.order, li.invoice {
    margin: 10px;
    border: 1px solid #DDD;
    background-color: #EEE;

    &:hover {
      cursor: pointer;
      background-color: #DDD;
      border-color: #CCC;

      div.order-details {
        border-color: #CCC;
      }
    }

    div.order-details {
      border-top: 1px solid #DDD;
      padding: 10px;
      display: none;
      background-color: white;
    }

    div.order-heading, div.invoice-heading {
      padding: 10px;
      //border-bottom: 1px solid #DDD;
    }

    span.label {
      font-size: 85%;
    }

    hr {
      border-top: 1px solid #EEE;
    }
  }
}

/* FOOTER */
/* podari-buket */
footer {
  h4 {
    font-weight: 300;
    font-size: 20px;
    color: #a0a0a0;
    text-transform: capitalize;
  }

  ul {
    padding: 0;

    li {
      list-style-type: none;

      a {
        color: white;
        display: inline-block;
        font-weight: 400;
        padding: 5px 0;

        &:hover {
          color: #EAA94F;
        }
      }
    }
  }

  a {
    color: #a0a0a0;

    &:hover {
      color: darken(#a0a0a0, 15%);
    }
  }

  .delivery-destinations {
    a {
      margin-right: 5px;
      padding-right: 5px;
      border-right: 1px solid #a0a0a0;
      line-height: 12px;

      &:last-child {
        border-right: none;
      }
    }
  }
}

footer.footer-top {
  background: #2a2a2a;
  border-bottom: 1px solid #171717;
}

ul#social-networks {
  li.social-network {
    display: block;
    width: 50px;
    height: 50px;
    background: #3c3c3c;
    float: left;
    margin-right: 1px;

    a {
      color: #2a2a2a;

      i {
        padding: 2px 5px;

        &.fa-wordpress {
          padding-left: 2px
        }
      }

      &:hover {
        color: lighten(#2a2a2a, 25%)
      }
    }
  }
}

footer.footer-middle {
  background: #3c3c3c;
  border-top: 1px solid #464646;
  border-bottom: 1px solid #171717;
  padding-bottom: 30px;
}

footer.footer-bottom {
  background: #2a2a2a;
  border-top: 1px solid #363636;
  color: #a0a0a0;
  font-size: 11px;
  padding: 15px 0;
}

/* end podari-buket */
section.footer-content {
  overflow: hidden;
  margin: 20px auto;
  background-color: white;
  min-height: 200px;
  border: 1px solid #5fceea;

  ul.products-list-footer {
    padding-left: 0;

    li.product-footer {
      margin-top: 10px;
      list-style-type: none;
      border-bottom: 1px solid #EEE;
      position: relative;

      a {
        color: inherit;
      }

      &:hover {
        cursor: pointer;

        p {
          color: #666;
        }
      }

      h5 {
        margin-top: 15px;
        margin-bottom: 10px;
      }

      p {
        color: #999;
      }

      div.price {
        position: absolute;
        top: -5px;
        right: 5px;
        font-size: 85%;

        span.old-price {
          text-decoration: line-through;
          float: left;
          margin-top: 3px;
          background-color: #ed7260
        }

        span.new-price {
          float: left;
          font-size: 100%;
          margin-right: 2px;
        }
      }

      span.discount {
        position: absolute;
        bottom: 12px;
        left: 10px;
        font-size: 100%;
        transform: rotate(335deg);
        -webkit-transform: rotate(335deg);
        -moz-transform: rotate(335deg);
        -o-transform: rotate(335deg);
        -ms-transform: rotate(335deg);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  ul#footer-info-tabs {
    margin-top: 10px;
  }

  hr {
    margin-bottom: 0px;
  }
}

section#social-networks-bar {
  position: fixed;
  top: 30%;
  left: 5px;

  ul#social-bar {
    padding: 0px;

    li {
      list-style-type: none;

      &.facebook a {
        color: $facebook;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($facebook, 10%)
        }
      }

      &.twitter a {
        color: $twitter;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($twitter, 10%)
        }
      }

      &.google-plus a {
        color: $googlep;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($googlep, 10%)
        }
      }

      &.vk a {
        color: $vk;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($vk, 10%)
        }
      }

      &.youtube a {
        color: $youtube;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($youtube, 10%)
        }
      }

      &.vimeo a {
        color: $vimeo;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($vimeo, 10%)
        }
      }

      &.pinterest a {
        color: $pinterest;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($pinterest, 10%)
        }
      }

      &.flickr a {
        color: $flickr;
        @include opacity(0.70, 70);

        &:hover {
          color: darken($flickr, 10%)
        }
      }
    }
  }
}

footer#footer-links {
  div.column {
    //border-right: 1px dotted #5fceea;
    //&:last-child {
    //  border-right: none;
    //}

    h4 {
      //color: darken(#428bca, 10%);
    }

    ul.footer-links {
      padding: 0;

      li.link {
        list-style-type: none;
      }
    }
  }
}

footer#testimonials {
  //background-color: #2b2b2b;
  padding: 20px 0px;
  min-height: 200px;

  h3 {
    margin-top: 0px;
    color: darken(#428bca, 10%);

    small {
      color: #428bca;
    }
  }

  div.payment-logos {
    margin-top: 30px;
  }
}

footer#delivery-destinations {
  small {
    //font-size: 75%
  }

  // background-color: #2b2b2b;
}

footer#delivery-destinations {
  color: darken(#428bca, 10%)
}

footer#page-footer {
  padding-top: 20px;
  color: #428bca;
}

div#frontpage-promo {
  margin-bottom: 15px;
  overflow: hidden;
}

//$facebook: #3b5998;
//$twitter: #00aced;
//$googlep: #dd4b39;
//$youtube: #e02a26;
//$pinterest: #cb2027;
//$flickr: #ff0084;
//$tumblr: #32506d; //yok
//$vk: #45668e;
//$vimeo: #aad450;
//$foursquare: #00aeef;
//$wordpress: #21759b;
//$blogspot: #ff9334; //yok

/*----------------------------------------------------------------*/
/*    Autumn's Form Elements
/*----------------------------------------------------------------*/
.form_info {
  font-size: 10px;
}

input[type=submit] {
  cursor: pointer;
}

input[type=text],
input[type=password] {
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 36px !important;
  padding: 5px 10px !important;
}

textarea {
  resize: vertical;
  padding: 5px 10px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
}

.input-group {
  &.full-width {
    width: 100%;
  }
}

form.full-width {
  input, textarea {
    width: 100%;
  }
}

/* Text - Light */
input[type=text].light,
input[type=password].light,
input[type=email].light,
textarea.light {
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #fafafa !important;
  margin: 0 !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 2px !important;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 0 0 rgba(255, 255, 255, 0.45) !important;

  &.full-width {
    width: 100% !important;
  }
}

input[type=text].light:focus,
input[type=email].light:focus,
input[type=password].light:focus,
textarea.light:focus {
  background: #ffffff !important;
  outline: 0;
}


/* Text - Dark */
input[type=text].dark,
input[type=password].dark {
  border: 0 !important;
  background: #2a2a2a !important;
  color: #a0a0a0 !important;
  font-weight: 400 !important;
  font-style: italic !important;
  height: 34px !important;
  border-radius: 2px !important;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.095), inset 0 1px 1px 0 rgba(0, 0, 0, 0.25) !important;
}

label.forinput {
  cursor: pointer;
  font-weight: 600 !important;
  color: #666666 !important;
}


/* Text - Light */
input[type=text].white,
input[type=password].white {
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #ffffff !important;
  margin: 0 !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 2px !important;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(255, 255, 255, 0.45) !important;
}


/* Button and Submit */
input[type=button].button,
input[type=submit].button,
.autumn-button {
  box-sizing: border-box;
  border: 1px solid #c0c0c0;
  border-radius: 2px;
  background-image: linear-gradient(#f8f8f8, #e7e7e7);
  background-clip: padding-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.35);
  width: auto;
  height: 32px;
  line-height: 30px;
  padding: 0 12px;
  margin: 0;
  text-shadow: none;
  color: #666666 !important;
  font-weight: bold;
  cursor: pointer;
}

a.autumn-button {
  line-height: 20px !important;
  padding: 6px 12px 7px 12px !important;
}

input[type=button].button:hover,
input[type=submit].button:hover,
.autumn-button:hover {
  background-image: linear-gradient(#fcfcfc, #f5f5f5);
  background-position: 0 0;
}

a.autumn-button:hover {
  text-decoration: none;
}

input[type=button].button:active,
input[type=button].button:focus,
input[type=submit].button:active,
input[type=submit].button:focus,
.autumn-button:active,
.autumn-button:focus,
ul.nav-pills li.active a.autumn-button {
  background-color: rgba(0, 0, 0, 0) !important;
  background-image: linear-gradient(#f5f5f5, #f8f8f8);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.55), inset 0 0 1px 0 rgba(0, 0, 0, 0.15);
}

input[type=button].button[disabled],
input[type=button].button[disabled] input[type=button].button:active,
input[type=button].button[disabled] input[type=button].button:focus,
input[type=submit].button[disabled],
input[type=submit].button[disabled] input[type=submit].button:active,
input[type=submit].button[disabled] input[type=submit].button:focus,
.autumn-button.disabled,
.autumn-button.disabled:hover,
.autumn-button.disabled:active,
.autumn-button.disabled:focus {
  color: #bbbbbb !important;
  background-image: linear-gradient(#f8f8f8, #efefef);
  box-shadow: none;
  cursor: default;
}

/* Red Add to Cart Button - input element */
.autumn_add_to_cart {
  display: block;
  background-color: #da3b44;
  color: #fafafa !important;
  height: 35px;
  padding: 0 30px;
  border: 1px solid #a40f18;
  cursor: pointer;
  font-weight: 700;
  line-height: 30px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.autumn_add_to_cart:hover {
  background-color: #e6434c;
  text-decoration: none;
}

.autumn_add_to_cart:active {
  background-color: #e6434c;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.35), 0 1px 0 0 rgba(255, 255, 255, 0.6);
}

.autumn_add_to_cart:disabled {
  color: #da3b44 !important;
  box-shadow: none;
}

/* Red Add to Cart Button - a element fix */
.autumn_add_to_cart.link {
  line-height: 33px !important;
  display: inline-block;
  white-space: nowrap;
  float: none;
  clear: both;
}

/* Red Add to Cart Button - product hover */
.autumn_add_to_cart_ph {
  display: block;
  background-color: #da3b44;
  background-image: url('../img/autumn/cart_white.png');
  background-repeat: no-repeat;
  background-position: 13px 50%;
  color: #fafafa !important;
  line-height: 35px;
  height: 35px;
  padding: 9px 16px 9px 46px;
  border: 1px solid #a40f18;
  cursor: pointer;
  font-weight: 700;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.autumn_add_to_cart_ph:hover {
  background-color: #e6434c;
  text-decoration: none;
}

.autumn_add_to_cart_ph:active {
  background-color: #e6434c;
}

.autumn_add_to_cart_ph:disabled {
  color: #da3b44 !important;
  box-shadow: none;
}

/* Selectbox */
select {
  border: 1px solid #c0c0c0;
  padding: 4px;
  height: 32px;
  line-height: 32px;
  color: #555555;
}

/* Radio & Checkboxes */

input[type=radio] {
  margin: 1px 1px 0 0 !important;
  vertical-align: text-top !important;
}

input[type=checkbox] {
  margin: 2px 1px 0 0 !important;
  vertical-align: text-top !important;
}


/* Noty Buttons */
.autumn-button.noty {
  height: 35px !important;
}

.autumn_add_to_cart.noty {
  display: inline !important;
}

/* Serkan's Fieldsets */
fieldset.autumn {
  background: #ffffff;

  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 6px solid #da3b44;

  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);

  margin: 0;
  min-height: 360px;
  text-align: center;
}

div.autumn-notification {
  background: #ffffff;

  border-left: 6px solid #da3b44;
  border-right: none;
  border-bottom: none;
  border-top: none;

  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);

  margin: 20px 0;
  min-height: 40px;
  padding: 15px;

  h5 {
    color: #444;
  }
}

div.form-placeholder {
  margin-top: 55px;
  margin-bottom: 55px;

  a {
    color: #666;
    font-weight: 600;
  }

  h3.title {
    background: transparent;
    text-shadow: none;
    border-bottom: 1px solid #c0c0c0;
    text-transform: none;
    margin: 0 20px;
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
  }

  p.checkbox {
    input[type=checkbox] {
      position: relative;
      width: initial;
    }

    label {
      width: initial;
      color: #666;
      margin-top: 0px;
      font-weight: 600;
      font-size: 12px;
      padding-left: 0px;
    }
  }
}

label {
  color: #666;
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
}

input {
  width: 220px;
}

input[type=checkbox] {
  width: initial;
}

div.reset-autumn-styles {
  input, label {
    width: initial !important;
  }
}

div.addresses, div.autumn-block {
  background: #ffffff;
  border: none;
  border-top: 6px solid #da3b44;

  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);

  min-height: 200px;

  overflow: hidden;

  form#saveDeliveryAddress {
    margin-top: 20px;
  }

  label {
    margin-top: 0px !important;
  }

  div.autumn-block-inner {
    padding: 15px;
    color: #444;
    font-weight: 400;
  }

  h3 {
    color: #444444;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;

    &.title {
      background: transparent;
      text-shadow: none;
      border-bottom: 1px solid #c0c0c0;
      text-transform: none;
      margin: 0 20px;
      padding: 20px 0;
      text-transform: uppercase;
      text-align: center;
    }
  }

  div.address {
    position: relative;
    margin: 20px 0 0 20px !important;
    display: block;
    background: #ffffff;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    border: 1px solid #c0c0c0;

    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);

    //margin: 0 20px 0 0;
    //min-width: 250px;
    width: 45% !important;
    min-height: 220px;
    text-align: center;
    float: left;

    &:last-child {
      margin-bottom: 20px !important;
    }

    div.address-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(242, 242, 242, 0.7);
      z-index: 999;

      h3 {
        margin-top: 45px
      }
    }

    h4.address-title {
      background: #fafafa;
      color: #444444;
      font-weight: 600;
      text-shadow: none;
      border-bottom: 1px solid #c0c0c0;
      margin-bottom: 10px;
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      margin-top: 0;
      position: relative;
      text-align: left;
      padding-left: 10px;

      span.address-control {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1001;

        button.exclusive-small {
          background-color: #da3b44;
          color: #fafafa !important;
          border: 1px solid #a40f18;
          -moz-border-radius: 2px;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
          -moz-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
          -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }

    p {
      font-size: 12px;
      color: #444;
    }
  }
}

label {
  color: #444;
  font-weight: 700;
  font-size: 13px; // New customer address form
}

.exclusive {
  margin-top: 10px;
  margin-bottom: 20px;

  display: inline-block;
  background-color: #da3b44;
  color: #fafafa !important;
  border: 1px solid #a40f18;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;

  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);

  padding: 6px 12px;

  &.btn-lg {
    margin-bottom: 10px !important;
  }

  &:hover {
    background-color: #e6434c;
    text-decoration: none;
    color: #fafafa !important;
  }
}

/* Common Table Style - autumn-table */
table.autumn-table,
table.std {
  width: 100%;
  background: #ffffff;
  margin-bottom: 20px;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border: 1px solid #c0c0c0;
  border-spacing: 0px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

table.autumn-table th,
table.std th {
  background: #fafafa;
  text-shadow: none;
  color: #444444;
  border-bottom: 1px solid #c0c0c0;
  font-weight: 600;
  text-transform: none;
  padding: 14px;
  text-align: center;
  white-space: nowrap;
}


table.autumn-table tr td,
table.std tr td {
  vertical-align: middle;
  padding: 18px !important;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  font-weight: 600;
  font-size: 12px;

  span.label {
    font-size: 90%;
  }
}

table.autumn-table tr td:first-child,
table.std tr td:first-child {
  border-left: 1px solid #c0c0c0 !important;
}

table.autumn-table tr td:last-child,
table.std tr td:last-child {
  border-right: 1px solid #c0c0c0 !important;
}

table.autumn-table .even,
table.std .even,
table.autumn-table .odd,
table.std .odd,
table.std tr.alternate_item,
table.table_block tr.alternate_item {
  background: none !important;
  background-color: transparent !important;
}


table.autumn-table tr.order-details {
  background: #ffffff !important;
  display: none;
}

table.autumn-table tfoot,
table.std tfoot {
  background: #fcfcfc;
  border-top: 1px solid #e9e9e9;
}

table.autumn-table tbody tr:last-child td,
table.std tbody tr:last-child td {
  border-bottom: none !important;
}


table.autumn-table tfoot tr:last-child td,
table.std tfoot tr:last-child td {
  border-bottom: none !important;
}


table.autumn-table tr.cart_discount td {
  border-top: 1px solid #e9e9e9;
}

table.autumn-table a:hover,
table.std a:hover {
  color: #da3b44 !important;
  text-decoration: none;
}

table.autumn-table a.exclusive:hover,
table.std a.exclusive:hover {
  color: #fafafa !important;
}

table.autumn-table a.autumn-button:hover,
table.std a.autumn-button:hover {
  color: #666666 !important;
}

ul.nav-tabs {
  li {
    &.active {
      a {
        background: #f2f2f2;
      }
    }
  }
}

div#cookie-law {
  background-color: #f2f2f2;
  border-top: 1px solid darken(#f2f2f2, 5%);
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;

  h4 {
    font-size: 13px;
    line-height: 20px;
  }

  .btn-cust {
    padding: 4px 8px !important;
  }

  .exclusive {
    margin: 0 0 0 5px;
  }
}
